<template>
  <title>{{ informations.title
    }}</title>
  <link rel="icon" type="image/jpg" :href="'https://admin.asso-refuge.fr/images/' + informations.logo" />

  <nav class="navbar navbar-expand-lg navbar-light " style="width: 100vw;top: 0px;">
    <a href="/">
      <img class="logo" :src="'https://admin.asso-refuge.fr/images/' + informations.logo" width="200px"
        :alt="informations.title">
    </a>

    <a href="/" class="logotexte">
      <h1> <span class="margegauche">{{ informations.title
          }} </span></h1>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse groupemid" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/', hash: '#refuge' }" @click="toggleNavbar()">Notre
            association</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <div v-for="page in pages" v-bind:key="page.id" class="topmenu">
          <li class="nav-item ">
            <router-link class="nav-link" :to="'/' + (page.path_website ? page.path_website : page.path)"
              @click="toggleNavbar">{{ page.name
              }}</router-link>
          </li>
          <p class="nav-link taille"><i class="fas fa-paw"></i></p>

        </div>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/', hash: '#actuID' }" @click="toggleNavbar()">Nos
            événements</router-link>
        </li>


      </ul>
    </div>
  </nav>
  <!-- 
  <div class="main_slider testheaderpicture" style="background-image:url('/assets/images/ctc.jpg'); height: 650px;">
       <div class="main_slider testheaderpicture" style="background-image:url(/images/catanddog.jpg)">


  </div>
-->
  <!-- route outlet -->
  <!-- component matched by the route will render here -->
  <router-view></router-view>
</template>

<style src="../assets/css/template.css"></style>
<style src="../assets/css/header.css"></style>
<script>


export default {
  data() {
    return {
      pages: [],
      informations: [],
    };
  },
  mounted() {
    const idSite = this.$idSite;
    fetch(`https://admin.asso-refuge.fr/getPages/${idSite}`)

      .then(response => response.json())
      .then(data => {
        // Combine all animals into a single array
        this.pages = data
        console.log(data);
      })
      .catch(error => {
        console.error('Il y a eu un problème avec votre requête :', error.message);
      });

    fetch(`https://admin.asso-refuge.fr/getInformations/${idSite}`)
      .then(response => response.json())
      .then(data => {
        this.informations = data[0];
        console.log(data[0]);
      })
      .catch(error => {
        console.error('Il y a eu un problème avec votre requête :', error.message);
      });
  },
  methods: {
    toggleNavbar() {
      if (window.innerWidth < 500) {
        var navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.click();
      }
    },
    toggleNavbarDisplay() {
      if (window.innerWidth < 500) {
        var navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.click();
      }
    },
  }
}

</script>