<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Les Besoins</h3>
            <br>

            <div class="mid">
            </div>
            <div class=" margetop" id="don" v-for="(besoin, index) in besoins" v-bind:key="besoin.id">
                <div class="row" v-if="index % 2 === 0">
                    <div class="seperation"></div>

                    <div class="col-lg-7">
                        <img class="test" :src="'https://admin.asso-refuge.fr/images/' + besoin.img" :alt="besoin.titre"
                            :title="besoin.titre">
                    </div>
                    <div class="col-lg-5">
                        <h3 class="font-weight-bold mb-4 justus">{{ besoin.titre }} </h3>
                        <p class="justu" v-html="besoin.content"></p>
                    </div>

                </div>
                <div class="row" v-else>


                    <div class="seperation"></div>

                    <div class="col-lg-1"></div>
                    <div class="col-lg-5">
                        <h3 class="font-weight-bold mb-4 justus">{{ besoin.titre }} </h3>
                        <p class="justu" v-html="besoin.content"></p>
                    </div>
                    <div class="col-lg-5">
                        <img class="test" :src="'https://admin.asso-refuge.fr/images/' + besoin.img" :alt="besoin.titre"
                            :title="besoin.titre">
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<style src="../assets/css/besoins.css"></style>
<script>
export default {
    data() {
        return {
            besoins: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getBesoins/${idSite}`)
            .then(response => response.json())
            .then(data => {
                const formattedBesoins = data.map(besoin => ({
                    ...besoin,
                    content: besoin.content.replace(/\r\n/g, '<br>'),
                }));
                console.log(formattedBesoins);
                this.besoins = formattedBesoins;
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
