<template>
    <div class="homeContainerRefuge">
        <br><br>

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Nos partenaires</h3>
        </div>

        <div class="container">

            <div class="row">
                <div class="col-lg-4" v-for="partenaire in  partenaires " v-bind:key="partenaire.id">
                    <div class="card" style="min-height:350px">
                        <img :src="'https://admin.asso-refuge.fr/images/' + partenaire.img" :title="partenaire.name"
                            style="width: 100%;" />
                        <div class="container titlecontainershop" style="margin-top: 20px;">
                            <h4><b>{{ partenaire.name }}</b></h4>
                            <br>
                            <h5 v-html="partenaire.descri"></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>
<style src="../assets/css/partenaire.css"></style>
<script>
export default {
    data() {
        return {
            partenaires: [],
        };
    },
    mounted() {
        const idSite = this.$idSite;
        fetch(`https://admin.asso-refuge.fr/getPartenaires/${idSite}`)
            .then(response => response.json())
            .then(data => {
                this.partenaires = data; // Mettez à jour chiens avec les données de l'API
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
