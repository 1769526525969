<template>
    <section class="center slider autoplay">
        <swiper :slides-per-view="calculateSlidesPerView()" :spaceBetween="1" :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }" :pagination="{
            clickable: true,
        }" :navigation="true" :modules="modules" class="mySwiper">
            <swiper-slide v-for="chien in  chiens " v-bind:key="chien.id">
                <div>
                    <img :src="chien.img1 ? 'https://admin.asso-refuge.fr/images/' + chien.img1 : 'https://www.admin.spadesavoie.fr/images/chienmanquant.png'"
                        :alt="'photo de l animal ' + chien.name + ' AILES DE LA PA'" :class="'tailleilg'"
                        :title="chien.name + ' ' + chien.race" />
                    <br>
                    <h5 class="namedog"> {{ chien.name }}</h5>
                    <hr style="width: 40%;">
                    <div class="souscontenu">
                        <h6><strong> Age: {{ calculateAge(chien.naissance) }}</strong> </h6>
                        <strong>Race : {{ chien.race }}</strong>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>


<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import convertAge from './getAge.js';
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        chiens: {
            type: Array,
            required: true // Indique que la prop chiens est requise
        },
    },
    setup() {
        return {
            modules: [Autoplay, Pagination, Navigation],
        };
    },
    methods: {
        calculateAge(dateOfBirth) {
            const age = convertAge(dateOfBirth)
            return age
        },
        calculateSlidesPerView() {
            return window.innerWidth < 600 ? 1 : 4;
        },

    }

};

</script>