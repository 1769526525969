<template>

  <div id="refuge" class="main_slider testheaderpicture"
    style="background-image:url('../assets/images/transibyflo.png'); height: 450px;">
    <div class="container fill_height">
      <div class="row align-items-center fill_height">
        <div class="col">
          <div class="main_slider_content">
          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="container">

    <h3 class="font-weight-bold mb-4 centers" id="top">Les Informations </h3>
    <div class="mid">
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="col-lg-4">
        <div class="col-lg-12">
          <!-- Check if the email content is not empty -->
          <div v-if="formattedEmail" class="card" style="margin-bottom: 0px;border-radius: 10px;">
            <div class="container" style="margin-top: 20px;">
              <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Email:</b></h4>
              <p v-html="formattedEmail" class=""></p>
            </div>
          </div>
        </div>


        <!-- Check if the address content is not empty -->
        <div class="col-lg-12">

          <div v-if="formattedAdresse" class="card" style="margin-bottom: 0px;border-radius: 10px;">
            <div class="container" style="margin-top: 20px;">
              <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Adresse:</b></h4>
              <p v-html="formattedAdresse" class=""></p>
            </div>
          </div>
        </div>

        <div class="col-lg-12">

          <!-- Check if the phone content is not empty -->
          <div v-if="formattedPhone" class="card" style="margin-bottom: 0px;border-radius: 10px;">
            <div class="container" style="margin-top: 20px;">
              <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Telephone:</b></h4>
              <p v-html="formattedPhone" class=""></p>
            </div>
          </div>
        </div>
        <div class="col-lg-12">

          <!-- Check if the schedule content is not empty -->
          <div v-if="formattedHorraire" class="card" style="margin-bottom: 0px;border-radius: 10px;">
            <div class="container" style="margin-top: 20px;">
              <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>Horraire:</b></h4>
              <p v-html="formattedHorraire" class=""></p>
            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-8">
        <div class="col-lg-12">

          <div v-if="formattedPresentation" class="card" style="margin-bottom: 0px;border-radius: 10px;">
            <div class="container" style="margin-top: 20px;">
              <h4 class="titleblue titlecontainershop" style="text-align: left;"><b>{{ informations.title }}:</b></h4>
              <p v-html="formattedPresentation" class=""></p>
            </div>
          </div>
        </div>

      </div>

    </div>




  </div>

  <br><br><br>
  <div class="seperation"></div>

  <div class="container">
    <h2 class="font-weight-bold mb-4 centers">Nos animaux à adopter</h2>
  </div>

  <homeData :chiens="animaux" />
  <div class="red_button shop_now_button buttonadaptatif bluenow "> <router-link style="color:white !important"
      to="/animaux">Voir tous
      nos animaux</router-link></div>




  <div class="homeContainerRefuge">
    <br><br>
    <div class="seperation"></div>

    <h2 class="font-weight-bold mb-4 centers">Les Derniers Evénements</h2>

    <table class="table" id="contact">
      <tbody>
        <tr v-for="newss in  news " v-bind:key="newss.id">
          <td>
            <img :src="'https://admin.asso-refuge.fr/images/' + newss.image" :class="'tailleimglogo'" style="" />
          </td>
          <td class='titleRefuge colorblue'>
            <router-link :to="'/publication/' + newss.id">
              {{ newss.nom }}
            </router-link>
          </td>
          <td class='titleRefuge notmobile'>{{ newss.resume }}</td>
          <td class='titleRefuge'>
            <router-link :to="'/publication/' + newss.id">
              <i class="fas fa-plus-circle"></i>
            </router-link>
          </td>
        </tr>

      </tbody>
    </table>
  </div>




  <div class="homeContainerRefuge">







    <br><br>

  </div>
</template>

<script setup>
import homeData from '../components/homeData.vue'

</script>
<script>
export default {
  data() {
    return {
      informations: [],
      news: [],
      animaux: [],

    };
  },
  mounted() {
    const idSite = this.$idSite;

    fetch(`https://admin.asso-refuge.fr/getInformations/${idSite}`)
      .then(response => response.json())
      .then(data => {
        // Vérifiez que data[0] existe et a un champ horraire
        if (data.length > 0) {
          this.informations = data[0];
          console.log(data[0]);
        } else {
          console.error('Les données reçues sont incorrectes ou vides.');
        }
      })
      .catch(error => {
        console.error('Il y a eu un problème avec votre requête :', error.message);
      });

    fetch(`https://admin.asso-refuge.fr/getNews/${idSite}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.news = data;
      })
      .catch(error => {
        console.error('Il y a eu un problème avec votre requête :', error.message);
      });

    fetch(`https://admin.asso-refuge.fr/getAnimal/${idSite}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Combine all animals into a single array
        this.animaux = data
      })
      .catch(error => {
        console.error('Il y a eu un problème avec votre requête :', error.message);
      });
  },


  computed: {
    formattedHorraire() {
      if (this.informations && this.informations.horraire) {
        // Remplacer \r\n par <br>
        return this.informations.horraire.replace(/\r\n/g, '<br>');
      }
      return '';
    },
    formattedEmail() {
      if (this.informations && this.informations.email) {
        // Remplacer \r\n par <br>
        return this.informations.email.replace(/\r\n/g, '<br>');
      }
      return '';
    },
    formattedAdresse() {
      if (this.informations && this.informations.address) {
        // Remplacer \r\n par <br>
        return this.informations.address.replace(/\r\n/g, '<br>');
      }
      return '';
    },
    formattedPresentation() {
      if (this.informations && this.informations.presentation) {
        // Remplacer \r\n par <br>
        return this.informations.presentation.replace(/\r\n/g, '<br>');
      }
      return '';
    },
    formattedPhone() {
      if (this.informations && this.informations.phone) {
        // Remplacer \r\n par <br>
        return this.informations.phone.replace(/\r\n/g, '<br>');
      }
      return '';
    }
  }
}
</script>